import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { App } from './components'
import GamePage from './views/GamePage/GamePage'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route index element={<GamePage />} />
    </Route>
  )
)
