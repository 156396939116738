const ButtonFullScreen = ({ canvasRef }) => {
  const toggleFullScreen = () => {
    const canvas = canvasRef.current
    if (canvas) {
      if (!document.fullscreenElement) {
        canvas.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  }

  return (
    <button
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
      }}
      onClick={toggleFullScreen}
    >
      Toggle Fullscreen
    </button>
  )
}

export default ButtonFullScreen
