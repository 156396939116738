import { useEffect, useState } from 'react'
import './Modal.scss'

const Modal = ({ show, timer }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className='modal__content'>
        <div className='modal__title'>Информация</div>
        <div className='modal__body'>
          <p>Ожидайте начало партии... {Math.round(timer / 1000)}</p>
        </div>
      </div>
    </div>
  )
}

export default Modal
