export const drawPlayers = (canvas, cellSize, mapOffset, players) => {
  const ctx = canvas?.getContext('2d')

  if (!players || !ctx) return
  Object.values(players).forEach((player, i) => {
    const absoluteX = player.x * cellSize - mapOffset.x
    const absoluteY = player.y * cellSize - mapOffset.y

    ctx.strokeStyle = 'red'
    ctx.fillStyle = 'white'
    ctx.font = '13px Arial'

    ctx.strokeRect(absoluteX, absoluteY, cellSize, cellSize)
    ctx.fillText(i + 1, absoluteX + 7, absoluteY + 15)
    ctx.fillText(player.life, absoluteX, absoluteY - 5)
    ctx.fillText(`D-${player.damage}`, absoluteX, absoluteY + 30)
  })
}

export const drawBackground = (canvas, img, mapOffset) => {
  const ctx = canvas.getContext('2d')
  const bg = document.getElementById(img)
  if (!bg) return
  ctx.drawImage(bg, -mapOffset.x, -mapOffset.y, bg.width * 2, bg.height * 2)
}

export const drawHighlightedCells = (canvas, map, mapOffset, cellSize) => {
  const ctx = canvas?.getContext('2d')

  if (!ctx || !map) return

  ctx.fillStyle = 'rgba(255, 0, 0 , 0.4)'
  for (let y = 0; y < map.length; y++) {
    for (let x = 0; x < map[y].length; x++) {
      if (map[y][x] === 1) {
        ctx.fillRect(
          x * cellSize - mapOffset.x,
          y * cellSize - mapOffset.y,
          cellSize,
          cellSize
        )
      }
    }
  }
}

export const drawDots = (canvas, mapOffset, cellSize, allDots) => {
  const ctx = canvas?.getContext('2d')
  //console.log('drawDots', allDots)
  if (!ctx || !allDots) return
  //удалить точки если пользователь дошел до них
  ctx.fillStyle = 'rgba(255, 255, 255 , 0.5)'
  for (const dot of allDots) {
    const dotX = dot.x * cellSize - mapOffset.x + cellSize / 2
    const dotY = dot.y * cellSize - mapOffset.y + cellSize / 2
    ctx.beginPath()
    ctx.arc(dotX, dotY, 4, 0, 2 * Math.PI)
    ctx.fill()
  }
}
