import './PlayerInfo.scss'

const PlayerInfo = ({ isSocketOpen, players, clientId, gameId }) => {
  return (
    <div>
      {isSocketOpen ? (
        <div className='green'>Socket is open</div>
      ) : (
        <div className='red'>Socket is not open</div>
      )}
      <h2>
        <span className='green'>Players:</span>{' '}
        {players && Object.keys(players).length}
      </h2>
      <h1>
        <span className='green'>Your Client ID:</span> {clientId}
      </h1>
      <div>
        <span className='green'>Your Game ID:</span> {gameId}
      </div>

      <div>
        <div className='grid'>
          <span className='green'>gameId</span>
          <span className='green'> id </span>
          <span className='green'> X/Y </span>
          <span className='green'>Life</span>
          <span className='green'>Damage</span>
        </div>
        {players &&
          Object.entries(players).map(([id, player], i) => (
            <div key={id} className='grid'>
              <span> {player.gameId}</span>
              <span>{id}</span>
              <span>
                {player.x}/{player.y}
              </span>
              <span>{player.life}</span>
              <span>{player.damage}</span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default PlayerInfo
