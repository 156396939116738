import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useWebSocket from '../../services/socketService'
import { ButtonFullScreen, LogPanel, Modal, PlayerInfo } from '../'
import * as layers from '../../utils/canvasUtils'
import './Game.scss'

const Game = () => {
  const {
    clientId,
    gameId,
    players,
    send,
    isSocketOpen,
    handlePlayerClick,
    logs,
    isModalTimerOpen,
    timer,
    map,
  } = useWebSocket()
  const [searchParams, setSearchParams] = useSearchParams()
  const [allDots, setAllDots] = useState([])
  const [canvasSize, setCanvasSize] = useState({ width: 600, height: 600 })
  const [hoveredCell, setHoveredCell] = useState([0, 0])
  const [mapOffset, setMapOffset] = useState({ x: 0, y: 0 })
  const canvasRef = useRef(null)

  const cellSize = 20

  useEffect(() => {
    setSearchParams({ ...searchParams, gameId: gameId })
  }, [gameId])

  const updateMapOffset = (players) => {
    const canvas = canvasRef.current
    const currentPlayer = players[clientId]

    if (currentPlayer && canvas) {
      const x = Math.max(0, currentPlayer.x * cellSize - canvas.width / 2)
      const y = Math.max(0, currentPlayer.y * cellSize - canvas.height / 2)
      setMapOffset({ x, y })
    }
  }
  useEffect(() => {
    updateMapOffset(players)
  }, [players, canvasSize])

  const drawCanvas = () => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')
    if (!ctx) return

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    layers.drawBackground(canvas, 'bg', mapOffset)
    layers.drawPlayers(canvas, cellSize, mapOffset, players)
    layers.drawHighlightedCells(canvas, map, mapOffset, cellSize)
    layers.drawDots(canvas, mapOffset, cellSize, allDots)

    if (hoveredCell) {
      const [x, y] = hoveredCell
      ctx.strokeStyle = 'rgba(0, 255, 0 , 1)'
      ctx.strokeRect(
        x * cellSize - mapOffset.x,
        y * cellSize - mapOffset.y,
        cellSize,
        cellSize
      )
    }
  }

  useEffect(() => {
    drawCanvas()
  }, [players, canvasSize, mapOffset, hoveredCell])

  useEffect(() => {
    if (players[clientId] && players[clientId].route) {
      setAllDots(players[clientId].route)
    }
  }, [players])

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current
    if (!canvas) return
    const rect = canvas.getBoundingClientRect()
    const clickX = e.clientX - rect.left
    const clickY = e.clientY - rect.top
    let selectedPlayer = null
    Object.entries(players).forEach(([id, player]) => {
      const playerX = player.x * cellSize - mapOffset.x
      const playerY = player.y * cellSize - mapOffset.y

      if (
        clickX >= playerX &&
        clickX <= playerX + cellSize &&
        clickY >= playerY &&
        clickY <= playerY + cellSize
      ) {
        selectedPlayer = id
      }
    })

    if (selectedPlayer) {
      handlePlayerClick(selectedPlayer)
      console.log(selectedPlayer, clickX, clickY)
    } else {
      const x = (clickX + mapOffset.x) / cellSize
      const y = (clickY + mapOffset.y) / cellSize

      send({ type: 'click', x, y })
    }
  }

  const handleMouseMove = (e) => {
    const canvas = canvasRef.current

    const rect = canvas.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top

    const x = Math.floor((mouseX + mapOffset.x) / cellSize)
    const y = Math.floor((mouseY + mapOffset.y) / cellSize)

    setHoveredCell([x, y])
  }

  useEffect(() => {
    const canvas = canvasRef.current

    if (canvas) {
      canvas.addEventListener('click', handleCanvasClick)
      canvas.addEventListener('mousemove', handleMouseMove)

      return () => {
        canvas.removeEventListener('click', handleCanvasClick)
        canvas.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [players])

  const handleKeyPress = (e) => {
    let x = 0
    let y = 0

    if (e.key === 'ArrowLeft') {
      x = -1
    }
    if (e.key === 'ArrowRight') {
      x = 1
    }
    if (e.key === 'ArrowUp') {
      y = -1
    }
    if (e.key === 'ArrowDown') {
      y = 1
    }

    send({ type: 'move', x, y })
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const handleResize = () => {
    const canvas = canvasRef.current
    if (canvas) {
      if (document.fullscreenElement) {
        canvas.width = document.fullscreenElement.clientWidth
        canvas.height = document.fullscreenElement.clientHeight
        setCanvasSize({
          width: document.fullscreenElement.clientWidth,
          height: document.fullscreenElement.clientHeight,
        })
      } else {
        canvas.width = 600
        canvas.height = 600
        setCanvasSize({ width: 600, height: 600 })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div style={{ padding: '10px' }}>
      <PlayerInfo
        isSocketOpen={isSocketOpen}
        players={players}
        clientId={clientId}
        gameId={gameId}
      />
      <img style={{ display: 'none' }} id='bg' src='/assets/img/3.jpg' alt='' />
      <canvas
        className='canvas'
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
      />
      <LogPanel logs={logs} />
      <ButtonFullScreen canvasRef={canvasRef} />
      <Modal show={isModalTimerOpen} timer={timer} />
    </div>
  )
}

export default Game
