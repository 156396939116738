import './LogPanel.scss'

const LogPanel = ({ logs }) => {
  return (
    <div className='log-panel'>
      <div className='log-panel__block'>
        {logs.map(({ message, t }, i) => {
          const currentTime = new Date(t)
          const hours = currentTime.getHours()
          const minutes = currentTime.getMinutes()
          const h = hours < 10 ? '0' + hours : hours
          const m = minutes < 10 ? '0' + minutes : minutes
          return (
            <p className='log-panel__item' key={i}>
              <span style={{ color: 'red' }}>
                {h}:{m}
              </span>
              -{message}
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default LogPanel
