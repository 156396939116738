import { useEffect, useRef, useState } from 'react'

const useWebSocket = () => {
  const [clientId, setClientId] = useState(null)
  const [gameId, setGameId] = useState(null)
  const [players, setPlayers] = useState({})
  const [isSocketOpen, setIsSocketOpen] = useState(true)
  const [logs, setLogs] = useState([])
  const [timer, setTimer] = useState(0)
  const [isModalTimerOpen, setIsModalTimerOpen] = useState(false)
  const [map, setMap] = useState([])
  const socketRef = useRef(null)

  useEffect(() => {
    const initializeSocket = () => {
      //const newSocket = new WebSocket('ws://localhost:3000')
      const newSocket = new WebSocket('ws://ws.firm.kiev.ua:3000')

      newSocket.onopen = () => {
        console.log('WebSocket connection opened')
      }

      newSocket.onclose = () => {
        console.log('WebSocket connection closed')
      }

      newSocket.onerror = (error) => {
        console.error(`WebSocket connection error: ${error}`)
      }

      newSocket.onmessage = (event) => {
        const data = JSON.parse(event.data)

        if (data.type === 'init') {
          setMap(data.map)
          //console.log(`Connected to server. Your clientId is ${data.clientId}`)
          setClientId(data.clientId)
          setGameId(data.gameId)
          console.log('init', data)
          newSocket.send(
            JSON.stringify({
              type: 'init',
              clientId: data.clientId,
              gameId: data.gameId,
            })
          )
        }

        if (data.type === 'update') {
          //console.log('Received player update from server:', data.players)
          setPlayers(data.players)
        }

        if (data.type === 'log') {
          console.log('Received log from server:', data.message)
          setLogs((prevLogs) => [
            ...prevLogs,
            { message: data.message, t: data.t },
          ])
        }

        if (data.type === 'timer') {
          setTimer(data.timer)
          setIsModalTimerOpen(true)
          console.log('Received timer from server:', data)
        }
        if (data.type === 'startGame') {
          setIsModalTimerOpen(false)
          console.log('Received modal from server:', data)
        }
      }

      socketRef.current = newSocket
    }

    initializeSocket()

    //return () => {
    //  if (socketRef.current) {
    //    socketRef.current.close()
    //  }
    //}

    const interval = setInterval(() => {
      if (
        socketRef.current &&
        socketRef.current.readyState !== WebSocket.OPEN
      ) {
        setIsSocketOpen(false)
        clearInterval(interval)
        console.log('WebSocket connection closed by server or lost.')
      }
      console.log('WebSocket connection is open.')
    }, 5000)

    return () => {
      if (socketRef.current) {
        socketRef.current.close()
      }
      clearInterval(interval)
    }
  }, [])

  const generateRandomDamage = () => {
    return Math.floor(Math.random() * 10) + 1
  }

  const send = (data) => {
    if (socketRef.current) {
      socketRef.current.send(JSON.stringify(data))
      console.log('Sent to server:', data)
    }
  }

  const handlePlayerClick = (targetClientId) => {
    console.log(targetClientId)
    if (targetClientId) {
      const damage = generateRandomDamage()
      send({ type: 'attack', targetClientId, damage })
    }
  }

  return {
    clientId,
    gameId,
    players,
    send,
    isSocketOpen,
    handlePlayerClick,
    logs,
    isModalTimerOpen,
    timer,
    map,
  }
}

export default useWebSocket
